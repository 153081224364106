<template>
    <!-- Search toolbar container -->
    <div
        class="search-toolbar items-center row no-wrap"
        :class="{ focus: isSearchInputFocused }"
        v-if="selectedSearchContext"
    >
        <!-- Dropdown for selecting search context -->
        <ContextDropdown
            :available-search-contexts="availableSearchContexts"
            :selected="selectedSearchContext?.type ?? 'all'"
            @on-selection="onContextSelect"
            class="col-shrink"
        />

        <!-- Input field for search -->
        <q-input
            data-qs="header-search-input"
            class="search-input col"
            v-model="searchInputValue"
            @keyup="handleKeyUp"
            @keydown="handleKeyDown"
            :placeholder="placeHolderText"
            clearable
            clear-icon="fa-regular fa-xmark"
            borderless
            @clear="onClear"
            @focus="onInputFocus"
            @blur="onInputBlur"
            ref="searchInputRef"
        >
            <!-- Dropdown menu for search, search suggestions & search history -->
            <q-menu
                v-if="showSuggestions"
                fit
                no-route-dismiss
                no-focus
                no-refocus
                :target="isXSmall ? `.header-component` : `.search-toolbar`"
                :auto-close="false"
                no-parent-event
                :model-value="showSuggestions"
                max-height="100vh"
            >
                <div class="q-py-md">
                    <!-- Display search suggestions only if there is input value and on top search suggestions if not mobile -->
                    <template v-if="searchInputValue.length > 0 && !isXSmall">
                        <SearchInContainer
                            :search-tabs="searchTabs ?? []"
                            :search-input-value="searchInputValue"
                            @on-context-selection="onContextSearchHandler"
                        />
                        <!-- Separator between search input and suggestions list -->
                        <q-separator v-if="suggestionsAll.length > 0" class="q-mb-md" />
                    </template>
                    <!-- Component to display search suggestions -->
                    <SearchSuggestions
                        :suggestions="suggestionsAll"
                        :search-input="searchInputValue"
                        :selected-index="focusedIndex"
                        @on-select="onSuggestionItemClick"
                        @on-select-text="onSelectSuggestionText"
                    />
                    <!-- Display search suggestions only if there is input value and below search suggestions if mobile -->
                    <template v-if="searchInputValue.length > 0 && isXSmall">
                        <!-- Separator between search input and suggestions list -->
                        <q-separator v-if="suggestionsAll.length > 0" class="q-mb-md" />
                        <SearchInContainer
                            :search-tabs="searchTabs ?? []"
                            :search-input-value="searchInputValue"
                            @on-context-selection="onContextSearchHandler"
                        />
                    </template>
                </div>
            </q-menu>
        </q-input>

        <!-- Search button -->
        <div class="col-auto">
            <QitButton
                round
                size="13px"
                icon="fa-regular fa-magnifying-glass"
                @click="() => onSearch()"
                data-qs="header-search-button"
                class="header-search-button q-ma-xs q-ml-sm"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import QitButton from "@/shared/components/buttons/qit-button.vue";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import { useSearchTabsFromAbilities } from "@/shared/environment/ability-search-tabs";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { useScreenSize } from "@/shared/screen/composables/screen-size";
import { parseQuery } from "@/shared/utils/lucene-query-parser/lucene-query-parser-wrapper";
import ContextDropdown from "@/shell/pages/header/search/context-dropdown.vue";
import {
    createSearchContextForDropDown,
    getPathToSearch,
    getPathToSearchWithFacet,
} from "@/shell/pages/header/search/header-search-utils";
import {
    SearchContextForDropDown,
    SearchContextForDropDownDetails,
    SearchItem,
    SearchState,
    SuggestionListItem,
} from "@/shell/pages/header/search/header-search.types";
import SearchInContainer from "@/shell/pages/header/search/search-in-container.vue";
import { MIN_SEARCH_LENGTH, useSearchSuggestions } from "@/shell/pages/header/search/search-suggestions";
import SearchSuggestions from "@/shell/pages/header/search/search-suggestions.vue";
import { watchImmediate } from "@vueuse/core";
import { useQuasar } from "quasar";
import { computed, nextTick, ref, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { RouteLocationRaw, useRoute } from "vue-router";

// Define the props for the component, with an optional searchPhrase
const props = defineProps<{
    searchPhrase?: string;
}>();

// Define the events that the component can emit
const emit = defineEmits<{
    (e: "onClickSearch", searchPath: RouteLocationRaw): void;
}>();

const route = useRoute();
const { t } = useI18n();
const { notify } = useQuasar();
const { isLessThanMedium, isXSmall } = useScreenSize();

// Default search context details for the dropdown
const searchContextForDropDownDetailsDefault: SearchContextForDropDownDetails = {
    icon: "",
    id: "",
    title: "",
    type: "all",
};

// Reactive references for various states and values
const focusedIndex = ref<number>(-1);
const searchInputValue = ref<string>("");
const searchCompleteInputValue = ref<string>("");
const searchInputRef = ref<HTMLElement>();
const availableSearchContexts = ref<SearchContextForDropDown>({});
const searchState = ref<SearchState>();
const isSearchInputFocused = ref<boolean>(false);
const showSuggestions = ref<boolean>(false);
const selectedSearchContext = ref<SearchContextForDropDownDetails | null>(null);

const { getCurrentSearchParams, getPathToChildWithoutParents, currentRouteStartsWith } = useAbilityRoute();

// Function to set the search states based on current search parameters
async function setSearchStates() {
    const searchParams = await getCurrentSearchParams();
    const { product, asset } = searchParams.searchParam;

    availableSearchContexts.value = await createSearchContextForDropDown(product, asset);
    searchState.value = searchParams;

    if (asset && availableSearchContexts.value["asset"])
        selectedSearchContext.value = availableSearchContexts.value["asset"];
    else if (product && availableSearchContexts.value["product"])
        selectedSearchContext.value = availableSearchContexts.value["product"];
    else selectedSearchContext.value = searchContextForDropDownDetailsDefault;
}

// Import search tabs from abilities
const { searchTabs } = useSearchTabsFromAbilities();

// Watch effect to set search states when search tabs change
watchEffect(async () => {
    if (searchTabs.value) await setSearchStates();
});

// Import search suggestions and related functions
const { saveHistoryItems, suggestionsAll, load } = useSearchSuggestions(
    searchCompleteInputValue,
    searchTabs,
    computed(() => searchState.value?.searchParam ?? {})
);

// Function to clear the search input values
const onClear = () => {
    searchCompleteInputValue.value = "";
    searchInputValue.value = "";
};

// Watcher to reset the focused index when suggestions change
watch(suggestionsAll, () => {
    focusedIndex.value = -1;
});

// Computed property to get the placeholder text for the search input
const placeHolderText = computed(() => {
    if (!isLessThanMedium.value || !selectedSearchContext.value) return t("core.Search");

    if (selectedSearchContext.value.type === "all") return t("core.Search");

    return `${t("core.Search")} ${selectedSearchContext.value.title}`;
});

// Computed property to get the list of selectable suggestions
const selectableSuggestions = computed(() => {
    const items: SuggestionListItem[] = [];

    suggestionsAll.value.forEach((x) => {
        items.push(...x.items);
    });

    return items;
});

/**
 * Handles the selection of a suggestion item from the search suggestions dropdown.
 * @param item - The selected suggestion item.
 * @Type {SuggestionListItem}
 */
const onSuggestionItemClick = (item: SuggestionListItem) => {
    searchInputValue.value = item.text;
    if (item.itemType === "facet") {
        onFacetSelected(item);
    } else {
        onSearch(item.searchTab?.alias ?? "");
    }
};

/**
 * Handles the search event and navigates to the search page.
 * @param shortCutTab - The tab to search in.
 * @type {string}
 * @param item - The selected suggestion item.
 * @Type {SuggestionListItem}
 */
const onSearch = async (shortCutTab: string = "", item?: SuggestionListItem) => {
    try {
        // Reset the focused index to its initial state.
        focusedIndex.value = -1;

        // Hide the search suggestions dropdown.
        showSuggestions.value = false;

        // Parse the search query to extract relevant information.
        parseQuery(searchInputValue.value, t);

        // Create a search item object containing the search value and the tab.
        const searchItem: SearchItem = {
            searchValue: searchInputValue.value || "",
            tab: item?.searchTab?.alias ?? shortCutTab,
        };

        let activeRoute: CommonRouteAliases | undefined = undefined;
        if (currentRouteStartsWith([CommonRouteAliases.search])) {
            activeRoute = CommonRouteAliases.search;
        } else if (currentRouteStartsWith([CommonRouteAliases.documentations])) {
            activeRoute = CommonRouteAliases.documentations;
        } else if (currentRouteStartsWith([CommonRouteAliases.activities])) {
            activeRoute = CommonRouteAliases.activities;
        }

        // Generate the search path and query parameters based on the search item and current state.
        const { searchParamString, queryParams } = getPathToSearch(
            searchItem,
            searchState.value?.searchTab,
            route.query,
            selectedSearchContext.value!.type,
            availableSearchContexts.value,
            activeRoute
        );

        // Construct the final search path for navigation.
        const searchPath = getPathToChildWithoutParents(
            CommonRouteAliases.search,
            { [CommonRouteAliases.search]: searchParamString },
            queryParams
        );

        // Save the search item to the search history.
        if (item) {
            await saveHistoryItems(item);
        } else if (shortCutTab) {
            const searchTab = searchTabs.value.find((tab) => tab.alias === shortCutTab);
            if (searchTab) {
                await saveHistoryItems({
                    text: searchInputValue.value,
                    itemType: "all",
                    searchTab: { alias: searchTab?.alias, displayText: "" },
                });
            }
        } else {
            await saveHistoryItems({ text: searchInputValue.value, itemType: "all" });
        }

        // Emit an event to trigger the search navigation.
        emit("onClickSearch", searchPath);

        // Remove focus from the search input field.
        searchInputRef.value?.blur();
    } catch (e: any) {
        // Notify the user of any errors that occur during the search process.
        notify({ message: e, type: "negative", timeout: 5000 });
    }
};

/**
 * Handles the selection of a facet from the search suggestions dropdown.
 * @param item - The selected suggestion item.
 * @Type {SuggestionListItem}
 */
const onFacetSelected = async (item: SuggestionListItem) => {
    try {
        // Hide the search suggestions dropdown.
        showSuggestions.value = false;

        // Create a search item object containing the search phrase and the tab alias.
        const searchItem: SearchItem = { searchValue: props.searchPhrase ?? "", tab: item.searchTab?.alias ?? "" };

        // Generate the search path and query parameters based on the search item and selected facet.
        const { searchParamString, queryParams } = getPathToSearchWithFacet(
            searchItem,
            item.id ?? "",
            item.value ?? "",
            selectedSearchContext.value!.type,
            availableSearchContexts.value
        );

        // Construct the final search path for navigation.
        const searchPath = getPathToChildWithoutParents(
            CommonRouteAliases.search,
            { [CommonRouteAliases.search]: searchParamString },
            queryParams
        );

        // Save the search item to the search history.
        await saveHistoryItems(item);

        // Clear the search input and other related states.
        onClear();

        // Emit an event to trigger the search navigation.
        emit("onClickSearch", searchPath);

        // Remove focus from the search input field.
        searchInputRef.value?.blur();
    } catch (e: any) {
        // Notify the user of any errors that occur during the search process.
        notify({ message: e, type: "negative", timeout: 5000 });
    }
};

/**
 * Handles the selection of a suggestion text from the search suggestions dropdown.
 * @param text - The text of the suggestion.
 * @type {string}
 * @param setFocus - A flag indicating whether to set focus on the search input field.
 * @type {boolean}
 */
const onSelectSuggestionText = (text: string, setFocus: boolean) => {
    searchInputValue.value = text;
    if (setFocus) {
        searchInputRef.value?.focus();
    }
};

/**
 * Handles the selection of a search context from the search context dropdown.
 * @param context - The selected search context.
 * @type {SearchContextForDropDownDetails}
 */
const onContextSelect = (context: SearchContextForDropDownDetails) => {
    selectedSearchContext.value = context;
};

/**
 * Handles the search completion event.
 * @param show - A flag indicating whether to show the search suggestions dropdown.
 * @type {boolean}
 */
const handleSearchCompletion = async (show: boolean) => {
    searchCompleteInputValue.value = searchInputValue.value;
    if (searchCompleteInputValue.value.length >= MIN_SEARCH_LENGTH) {
        load();
        if (show) {
            showSuggestions.value = false;
            await nextTick();
            showSuggestions.value = true;
        }
    }
};

/**
 * Handles the search event for a specific search context.
 * @param searchInCategory - The search context to search in.
 * @type {string}
 */
watchImmediate(
    () => props.searchPhrase,
    async () => {
        searchInputValue.value = props.searchPhrase ?? "";
        await handleSearchCompletion(false);
    }
);

/**
 * Handles the search event for a specific search context.
 * @param searchInCategory - The search context to search in.
 * @type {string}
 */
const onContextSearchHandler = (searchInCategory: string) => {
    onSearch(searchInCategory);
};

const onInputFocus = async () => {
    isSearchInputFocused.value = true;
    //workaround is necessary to ensure that the suggestion menu is displayed
    showSuggestions.value = false;
    await nextTick();
    showSuggestions.value = true;
    //------------------------------------------------------------------------
};

const onInputBlur = async () => {
    isSearchInputFocused.value = false;
};

const onSelectByIndex = (index: number) => {
    if (index < 0) onSearch();

    const selectedItem: SuggestionListItem = selectableSuggestions.value[index];
    if (!selectedItem) return;

    if (selectedItem?.itemType !== "facet") {
        searchInputValue.value = selectedItem.text;
        onSuggestionItemClick(selectedItem);
        return;
    }

    onFacetSelected(selectedItem);
};

//-------Keyboard navigation------------------

const handleKeyUp = async (event: KeyboardEvent) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown" || event.key === "Enter") {
        return;
    }
    await handleSearchCompletion(true);
};

const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "ArrowUp") {
        event.preventDefault();
        moveSelectionUp();
    } else if (event.key === "ArrowDown") {
        event.preventDefault();
        moveSelectionDown();
    } else if (event.key === "Tab") {
        event.preventDefault();
        completeSuggestion();
    } else if (event.key === "Enter") {
        event.preventDefault();
        onSelectByIndex(focusedIndex.value);
    }
};

const moveSelectionUp = () => {
    if (focusedIndex.value > 0) {
        focusedIndex.value--;
    } else {
        focusedIndex.value = selectableSuggestions.value.length - 1;
    }
};

const moveSelectionDown = () => {
    if (focusedIndex.value < selectableSuggestions.value.length - 1) {
        focusedIndex.value++;
    } else {
        focusedIndex.value = 0;
    }
};

const completeSuggestion = () => {
    const suggestionListItem = selectableSuggestions.value[focusedIndex.value];
    onSelectSuggestionText(suggestionListItem.text, false);
};

//---------------------------------
</script>

<style lang="scss" scoped>
.search-toolbar > input:focus {
    border: 1px solid var(--q-primary);
}
.search-toolbar {
    background-color: $light-background-color;
    border: 1px solid #ddd;
    border-radius: 25px;
    max-height: 50px;
    .search-input {
        width: 100%;
        margin-left: $spacing-l;
        min-width: 50px;
    }
    &:hover {
        border: 1px solid $grey-60;
    }

    &:active,
    &:focus,
    &.focus {
        border: 1px solid var(--q-primary);
        box-shadow: $primary-color-shadow;
    }

    .screen--xs & {
        width: auto;
    }

    hr {
        height: 1px;
        background-color: #ddd;
        border: none;
    }

    .container-in-search-suggestions {
        padding: 10px;

        .black-text {
            color: $default-text-color;
            font-size: larger;
        }
    }
}
</style>
