<template>
    <q-list v-if="items.length > 0">
        <q-item
            v-for="item in items"
            :key="item.id"
            clickable
            v-close-popup
            @click="onClick(item)"
            class="items-center"
            :data-qs="`dropdown-item-${item.id}`"
        >
            {{ item.text }}
            {{ aggregations && aggregations[item.id] > 0 ? `(${aggregations[item.id] ?? "0"})` : "(0)" }}
        </q-item>
    </q-list>
</template>

<script setup lang="ts">
import { FacetValue } from "../../facets.model";

defineProps<{
    facetType: string;
    items: FacetValue[];
    aggregations: Record<string, number>;
    searchEnabled?: boolean;
}>();

const emits = defineEmits<{
    onSelect: [selectedId: string];
    onFilterChanged: [filterPhrase: string];
}>();

const onClick = (val: FacetValue) => {
    emits("onSelect", val.id);
};
</script>

<style lang="scss" scoped>
.items-center {
    padding: 0 16px;
}
</style>
