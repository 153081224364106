import gql from "graphql-tag";
import { SearchContext } from "@/shared/search/search.types";

export const generateSearchDocumentsQuery = (
    dataFieldsFragment: Record<string, string[]>,
    localizationDatafieldsFragment: Record<string, string[]>,
    fields: Record<string, string>,
    context?: SearchContext
) => {
    const allDataFieldFragments = dataFieldsFragment["all"]?.join(" ") ?? "";
    const allLocalizedDataFieldFragments = localizationDatafieldsFragment["all"]?.join(" ") ?? "";
    const topicDataFieldFragments = dataFieldsFragment["topic"]?.join(" ") ?? "";
    const topicLocalizedDataFieldFragments = localizationDatafieldsFragment["topic"]?.join(" ") ?? "";
    const contentMapDataFieldFragments = dataFieldsFragment["contentMap"]?.join(" ") ?? "";
    const contentMapLocalizedDataFieldFragments = localizationDatafieldsFragment["contentMap"]?.join(" ") ?? "";

    const { topic: topicFields, contentMap: contentFields } = fields;

    const topiclocalizationFragment = `teasers {
            title(length: 256) 
            ${allLocalizedDataFieldFragments} 
            ${topicLocalizedDataFieldFragments} 
        }`;

    const contentMaplocalizationFragment = `teasers {
            title(length: 256) 
            ${allLocalizedDataFieldFragments} 
            ${contentMapLocalizedDataFieldFragments} 
        }`;

    const contentMapFragment = `... on ContentMap {
            ${contentFields ?? ""}
            ${contentMapDataFieldFragments ? `useContext {${contentMapDataFieldFragments}}` : ""}
            ${allDataFieldFragments ? `useContext {${allDataFieldFragments}}` : ""}
            ${contentMaplocalizationFragment}
        }`;

    const topicFragment = ` ... on Topic {
            ${topicFields ?? ""}
            ${topicDataFieldFragments ? `useContext {${topicDataFieldFragments}}` : ""}
            ${allDataFieldFragments ? `useContext {${allDataFieldFragments}}` : ""}
            ${topiclocalizationFragment}
            mimeType
        }`;

    const acceptedLanguageParam = (
        allLocalizedDataFieldFragments +
        contentMaplocalizationFragment +
        topicLocalizedDataFieldFragments
    ).includes("acceptedLanguages")
        ? ", $acceptedLanguages: [LanguageWithWildcard!]"
        : "";

    const contentsFragment = `contents(first: $first, after: $after, sort: $sort) {
        cursor
        highlights {
            title(length: 256)
            text(length: 256)
        }
        node {
            id
            contentId
            locale
            useContext {
                rootContentMap {
                    teasers {
                        title(length: 256)
                    }
                }
            } 

            ${contentMapFragment}
            ${topicFragment}
        }
    }`;

    if (context?.asset && context?.product)
        return gql` query searchDocumentsForAssetWithProduct($assetFilter: AssetFilter!, $contentFilter: ContentFilter!, $first: NonNegativeInt, $after: String, $sort: [ContentSort] ${acceptedLanguageParam} ) {
                        assets(filter: $assetFilter) {
                            assets(first:1) {
                                cursor
                                node {
                                id
                                relatedContents(filter: $contentFilter) {
                                    total(trackLimit: 500) {
                                        count
                                        countRelation
                                    }
                                    ${contentsFragment}
                                }
                            }
                        }
                    }
                } `;

    if (context?.product) {
        return gql`query searchRelatedContentsProduct($productId: String!,$first: NonNegativeInt, $after: String, $contentFilter: ContentFilter, $sort: [ContentSort] ${acceptedLanguageParam} ) {
                    product(id: $productId) {
                        id
                        relatedContents(filter: $contentFilter) {
                            total(trackLimit: 500) {
                                count
                                countRelation
                            }
                            ${contentsFragment}
                        }
                    }
                }`;
    }
    return gql`
    query searchDocuments($first: NonNegativeInt, $after: String, $contentFilter: ContentFilter, $sort: [ContentSort] ${acceptedLanguageParam} )  {
        contents(filter: $contentFilter) {
            total(trackLimit: 500) {
                count
                countRelation
            }
            ${contentsFragment}
        }
    }
`;
};
