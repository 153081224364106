<template>
    <FacetModalWrapper :loading="loading" :facet="facet" @on-close="onClose()">
        <FacetSearchField v-if="searchEnabled" v-model="filterPhrase" />
        <DropdownMultiSelectList
            v-if="(forceList || aggregations) && dropdownType === 'multi'"
            :aggregations="aggregations ?? {}"
            :items="facetValues"
            :selected="selected"
            :facet-type="facet.facetType"
            @on-selection-changed="onSelect"
        />
        <DropdownSingleSelectList
            v-else-if="(forceList || aggregations) && dropdownType === 'single'"
            :aggregations="aggregations ?? {}"
            :items="facetValues"
            :facet-type="facet.facetType"
            @on-select="onSingleSelect"
        />
        <DropdownTree
            v-else-if="aggregations && dropdownType === 'tree'"
            :aggregations="aggregations"
            :items="facetValues"
            :facet="facet"
            :selected="selected"
            @on-selection-changed="onSelect"
        />
        <div
            v-if="!loading && facetValues.length === 0"
            class="text-center q-pa-sm no-data-field-text"
            :class="{ 'max-width-desktop': isLargerThanMedium }"
        >
            {{ $t("core.There are no filter values available") }}
        </div>
    </FacetModalWrapper>
</template>

<script setup lang="ts">
import { computed, ref, toRefs, watch } from "vue";
import { Facet, FacetValue } from "@/shared/facets/facets.model";
import FacetModalWrapper from "@/shared/facets/dropdown/content/facet-modal-wrapper.vue";
import DropdownMultiSelectList from "@/shared/facets/dropdown/content/dropdown-multi-select-list.vue";
import DropdownSingleSelectList from "@/shared/facets/dropdown/content/dropdown-single-select-list.vue";
import DropdownTree from "@/shared/facets/tree/facet-tree.vue";
import { useScreenSize } from "@/shared/screen/composables/screen-size";
import FacetSearchField from "@/shared/facets/facet-search-field.vue";

type DropdownDisplayType = "tree" | "multi" | "single";

const props = defineProps<{
    facet: Facet;
    loading: boolean;
    facetValues: FacetValue[];
    selected: FacetValue[];
    onSelect: (selectedIds: string[]) => void;
    aggregations?: Record<string, number>;
}>();

const emits = defineEmits<{
    onModalClosed: [];
    onFilterChanged: [filterPhrase: string];
}>();

const MIN_VALUES_LENGTH_FOR_SEARCH = 12;
const { facet, loading, facetValues, selected, aggregations } = toRefs(props);
const filterPhrase = ref<string>("");
const { isLargerThanMedium } = useScreenSize();

watch(filterPhrase, () => {
    emits("onFilterChanged", filterPhrase.value);
});

const searchEnabled = computed(() => {
    if (props.facet.facetType === "keyword") return true;

    if (props.facet.displayOptions?.searchField === undefined || props.facet.displayOptions?.searchField === true)
        return filterPhrase.value.length > 0 || facetValues.value?.length > MIN_VALUES_LENGTH_FOR_SEARCH;

    return false;
});

const forceList = computed(() => {
    return facet.value.facetType === "taxonomy" && filterPhrase.value.length > 0;
});

const dropdownType = computed((): DropdownDisplayType => {
    if (props.facet.displayOptions.isTree && !forceList.value) return "tree";
    if (props.facet.displayOptions.isMultiSelect) return "multi";

    return "single";
});

const onSingleSelect = (selectedId: string) => {
    props.onSelect([selectedId]);
};

const onClose = () => {
    emits("onModalClosed");
};
</script>

<style lang="scss" scoped>
.no-data-field-text {
    color: $grey-55;
}

.max-width-desktop {
    max-width: 190px; // width of dropdown menu
}
</style>
