<template>
    <q-tree
        v-if="nodes.length > 0"
        :nodes="nodes"
        node-key="id"
        label-key="teaser"
        children-key="childNodes"
        no-connectors
        icon="fa-solid fa-caret-right"
        :tick-strategy="facet.displayOptions.isMultiSelect ? `strict` : `none`"
        v-model:ticked="selectedIds"
        v-model:expanded="expandedIds"
        @update:ticked="updateTickedHandler"
    >
        //Single select template
        <template v-if="!facet.displayOptions.isMultiSelect" #default-header="{ node }">
            <div class="row items-center" @click.stop>
                <q-radio size="xs" :val="node.id" v-model="selectedId" @update:model-value="updateCheckboxValue" />
                <div>
                    {{ node.teaser }}
                </div>
            </div>
        </template>
    </q-tree>
</template>

<script setup lang="ts">
import { Facet, FacetValue } from "@/shared/facets/facets.model";
import { computed, ref, toRefs, watch } from "vue";
import { convertToTree } from "@/shared/facets/tree/facet-tree-converter";

const props = defineProps<{
    facet: Facet;
    items: FacetValue[];
    selected: FacetValue[];
    aggregations?: Record<string, number>;
    searchEnabled?: boolean;
}>();

const emits = defineEmits<{
    (e: "onSelectionChanged", selectedIds: string[]): void;
}>();

const { items, aggregations } = toRefs(props);
const selectedIds = ref<string[]>([]);
const expandedIds = ref<string[]>([]);
const selectedId = ref<string>("");

watch(
    () => props.selected,
    () => {
        if (props.facet.displayOptions.isMultiSelect) selectedIds.value = props.selected.map((x) => x.id);
        else selectedId.value = props.selected.length > 0 ? props.selected[0].id : "";

        const pathKeys = props.selected.filter((x) => x.pathKeys).flatMap((x) => x.pathKeys as string[]);
        expandedIds.value = [...new Set(pathKeys)];
    },
    { immediate: true }
);

const nodes = computed(() => {
    return convertToTree(items.value, props.facet.id, aggregations.value ?? {});
});

const updateTickedHandler = (selectedIds: readonly any[]) => {
    emits("onSelectionChanged", [...selectedIds]);
};

const updateCheckboxValue = (selectedId: string) => {
    emits("onSelectionChanged", [selectedId]);
};
</script>
