<template>
    <q-input v-model="filterPhrase" dense outlined class="q-ma-sm dropdown-search-field" data-qs="facet-search-field">
        <template #prepend>
            <q-icon name="fa-regular fa-magnifying-glass" />
        </template>
    </q-input>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

const props = defineProps<{
    modelValue: string;
}>();

const emits = defineEmits<{
    (e: "update:modelValue", value: string): void;
}>();

const filterPhrase = ref<string>(props.modelValue);

watch(filterPhrase, (newValue) => {
    emits("update:modelValue", newValue);
});

watch(
    () => props.modelValue,
    (newValue) => {
        filterPhrase.value = newValue;
    }
);
</script>

<style lang="scss" scoped>
.items-center {
    padding: 0 16px;
}

.dropdown-search-field {
    min-height: 34px;
}
</style>
