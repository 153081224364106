import { useAvailablePartsListQuery } from "@/shared/services/graphql/generated/consumer-graph-types";
import { I18TType } from "@/shared/i18n/i18-utils";
import { AccessAction, AccessResource } from "@/shared/access-control/access-control";
import { useHasAccess } from "@/shared/access-control/composables/use-has-access";
import { computed, ref, Ref, watch, watchEffect } from "vue";
import DeleteMechanicDialog from "./delete-mechanic-dialog.vue";
import { MenuContext } from "@/shared/environment/menu/menu.types";
import { getStore } from "@/shared/store/store";
import { AbilityMenuEntries, MenuEntryWithMetaData } from "@/shared/environment/ability.types";

export function useMoreMenuMechanicProductItems(menuContext: Ref<MenuContext>, t: I18TType): AbilityMenuEntries {
    const menuEntries = ref<MenuEntryWithMetaData[]>([]);

    const store = getStore();

    const { hasAccess: hasDeleteMechanicsAccess } = useHasAccess({
        resource: AccessResource.mechanics,
        action: AccessAction.delete,
    });

    const productId = computed(() => {
        if (menuContext.value.__type === "productDetail") {
            return menuContext.value.productId;
        } else {
            return undefined;
        }
    });

    const filterVariables = computed(() => {
        return {
            filter: {
                andGroup: [
                    {
                        equals: {
                            parentAssemblyId: "",
                        },
                    },
                ],
            },
            productId: productId.value,
            first: 1,
        };
    });

    const {
        loading: availablePartsListLoading,
        result: availablePartsListResult,
        refetch,
    } = useAvailablePartsListQuery(
        filterVariables,
        computed(() => ({ fetchPolicy: "no-cache", enabled: productId.value !== undefined }))
    );

    const hasMechanics = computed(() => {
        return (
            availablePartsListResult.value?.mechanics !== undefined &&
            !availablePartsListLoading.value &&
            availablePartsListResult.value.mechanics.mechanics &&
            availablePartsListResult.value.mechanics.mechanics.length > 0
        );
    });

    watch(
        () => store.state.notification.mechanicDeleted,
        (value) => {
            if (value > 0) {
                refetch();
            }
        }
    );

    const getMoreMenuEntries = () => {
        if (!productId.value) return [];

        return [
            {
                entry: {
                    icon: "fa-regular fa-trash error-color",
                    text: t("mechanic.Delete mechanics"),
                    click: () => {
                        menuContext.value.callerContext.showDialogComponent({
                            getComponent: () => DeleteMechanicDialog,
                            props: {
                                productId: productId.value,
                                notifyAfterAction: menuContext.value.callerContext.notifyDone,
                            },
                        });
                    },
                    elementAttrs: { "data-qs": "product-settings-toolbar-item-delete-mechanic-button" },
                    visible: hasDeleteMechanicsAccess.value && hasMechanics.value,
                },
                position: 2,
            },
        ];
    };

    watchEffect(() => {
        if (availablePartsListResult.value && productId.value)
            menuEntries.value =
                (availablePartsListResult.value.mechanics.mechanics?.length ?? 0) > 0 ? getMoreMenuEntries() : [];
    });

    return { menuEntries };
}
